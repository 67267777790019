.tooltip {
    $block-name: '.tooltip';
    position: relative;

    @media all and (max-width: 1249px) {
        margin-right: 100px;
    }


    @media all and (max-width: 768px) {
        text-align: center;
        margin-right: 0;
    }

    &:hover {
        .tooltip__popup {
            display: block;
        }
    }
    
    &__element { 
        opacity: 0.5; 
        border: 2px solid $colorGreen;
        background-color: transparent;
        color: $colorGreen;

        &:hover {
            color: $colorGreen;
            border-color:  $colorGreen;
            background-color: #fff;
        }
    }

    &__info {
        z-index: 5;
        opacity: 1;

        border-radius: 50%;
        display: inline-block;


        svg {
            fill: $colorBlue;
        }
    }

    &__popup {
        display: none;
        position: absolute;
        top: -65px;
        right: 50%;
        margin-right: -150px;
        padding: 10px;
        width: 300px;
        border-radius: 5px;
        background-color: $colorBlue;
        text-align: center;
        color: $colorWhite;
        font-size: 12px;
        z-index: 20;


        @media all and (min-width: 601px) and (max-width: 768px) {
            right: 0;
            margin-right: 0;
            text-align: center;
        }

        @media all and (max-width: 440px) {
            width: 200px;
            top: -75px;
            margin-right: -100px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;

            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $colorBlue;

            left: 50%;
            transform: translate(-50%, 100%);
            
            @media all and (min-width: 601px) and (max-width: 768px)  {
                right: 20%;
                left: 80%;
            }
        
        }
    }

    &--icon {
        margin: 0 16px;
        
        #{$block-name}__popup {
            background-color: $colorIce;
            color: $colorMain;
            top: 40px;
            right: -16px;
            margin-right: 0;
            border-radius: 14px;
            font-size: 14px;
            width: 500px;

            @media all and (max-width: 600px) {
                width: 400px;
            }

            @media all and (max-width: 440px) {
                width: 250px;
            }

            &::after {
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
                border-bottom: 8px solid $colorIce;
                left: initial;
                transform: translate(0);
                right: 22px;
                top: -16px;
            }
        }
    }

    &--orange {
        #{$block-name}__popup {
            background-color: $colorOrange;
            color: $colorBlue;

            &::after {
                border-top-color: $colorOrange;
            }
        }
    }

    &--basic {
        margin: 0;
        
        #{$block-name}__trigger {
            svg {
                transition: fill 0.2s ease;
            }
            &:hover {
                svg {
                    fill: $colorGreen;
                }
            }
        }
        #{$block-name}__popup {
            border-radius: 10px;
            top: -32px;
            margin-right: -60px;
            padding: 8px 8px 5px 8px;
            width: 120px;

            &::after {
                border-width: 6px;
                bottom: 0;
            }
        }
    }

    &--bottom {
        #{$block-name}__popup {
            top: 40px;

            &::after {
                top: -17px;
                bottom: initial;
                border-top: 0;
                border-bottom: 10px solid #1e2e54;
            }
        }

        #{$block-name}__trigger {
            &:not(a) {
                &:hover {
                    svg {
                        fill: $colorBlue;
                    }
                }
            }
        }
    }

    .popup & {
        .tooltip__popup {
            right: 0;
            margin-right: 0;

            &:after {
                right: 7%;
                left: 93%;
            }
        }
    }

    &--to-top {
        margin-top: -5px;
    }
}

