.trustpilot {
    margin-top: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    color: $colorMain;

    @media all and (max-width: 991px) {
        margin: 0 auto;
        padding: 0 20px;
        font-size: 14px;
        border-radius: 20px;
        max-width: 272px;
        text-align: center;
    }

    &--block {
        display: block;
    }

    &-container-flex {
        padding-right: 35px;
        display: flex;
        border-radius: 20px;

        @media all and (max-width: 767px) {
            padding-right: 0;
            display: block;
        }

        .trustpilot {
            border-radius: 20px;
        }
    }

    &--padding-sm {
        padding: 40px;
    }

    &__reviews {
        margin: 10px 0;
        font-weight: 400;

        &-link {
            color: $colorMain;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }    

    &__headline {
        margin: 0 0 15px;
        font-size: 24px;
    }

    &__logo {
        margin-bottom: 15px;
        width: 180px;
        height: 45px;

        @media all and (max-width: 991px) {
            width: 140px;
            position: relative;
            right: 7px;
        }

        &--small {
            width: 150px;
            height: 37.5px;
        }
    }

    &__icon {

        &:not(:last-child) {
            margin-right: 5px;
        }

        &--green {
            background-color: $colorGreenEmerald;
        }

        &--lightGreen {
            background-color: $colorGreenLight;
        }

        &--gray {
            background-color: $colorGrayLight;
        }

        &-list {
            display: flex;
            list-style-type: none;

            &--center {
                justify-content: center;
            }
        }

        svg {
            width: 48px;
            height: 48px;

            @media all and (min-width: 375px) and (max-width: 1440px) {
                width: 42px;
                height: 42px;
            }

            @media all and (max-width: 374px) {
                width: 36px;
                height: 36px;
            }
        }

        &--small {

            svg {
                width: 24px;
                height: 24px;
    
            }
        }

        &--medium {

            svg {
                width: 36px;
                height: 36px;
    
            }
        }
    }

    &__info {
        font-weight: bold;

        &-link {
            color: $colorMain;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.trustpilot-review {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;

    &__stars {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        color: #777777;
        font-size: 16px;

        > * {
            margin-bottom: 15px;
        }
    }

    &__header {
        margin: 0;
        font-weight: bold;
    }

    &__text {
        margin: 10px 0;
        font-size: 16px;
    }

    &__sign {
        margin: auto 0 0;
        font-size: 16px;
        color: #777777;
    }

    &-container {
        margin: 0 auto;
        border-radius: 20px;

        &:before {

            @media all and (max-width: 767px) {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
                height: 1px;
                background-color: $colorMain;
            }
        }
    }

    .trustpilot__icon-list {
        padding-right: 20px;
    }

}