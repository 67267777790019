
.popup {
    position: fixed;
    z-index: 9999;
    box-shadow: $boxShadow;
    background-color: #fff;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;

    @media screen and (max-width: 1024px) { padding: 20px; }

    &:not(.popup--sidebar):not(.popup--centered) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1020px;
        max-height: 80vh;
        width: 92%;
        border-radius: 20px;

        &:not(.popup--with-footer) {
            padding: 40px;
        }
    }

    &--centered {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.5);
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 1024px) { padding: 0 20px; }
        
        .popup__close-layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
        .popup__content {
            max-width: 860px;
            overflow-y: auto;
            z-index: 20;
        }

        .popup__bottom {
            max-width: 860px;
            display: flex;
            justify-content: space-between;
            padding: .75rem 1.5rem;
            z-index: 20;
        }
    }

    &--sidebar {
        top: 0;
        right: 0;
        left: none;
        bottom: 0;
        max-width: 560px;
        max-height: none;
        width: 100%;
        border-radius: none;
        padding: 120px 60px 60px 60px;
    }

    &--overflow-visble {
        padding-bottom: 10px;
        overflow: visible;

        @media all and (max-width: 574px) {
            overflow-y: auto;
        }
    }

    &--narrow {
        max-width: 760px;
    }

    &--medium-narrow {
        max-width: 620px !important;
        padding: 30px !important;
    }

    &--top-spacing {
        top: calc(50% + 75px) !important;
        max-height: calc(80vh - 75px) !important;
    }

    &--super-narow {
        max-width: 540px;
    }

    &--regular {
        max-width: 1131px !important;
    }

    &--medium {
        max-width: 1348px !important;
    }

    &.show {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &__close {
        position: absolute;
        top: 14px;
        right: 14px;
        padding: 6px;
        background-color: transparent;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: 0;
        }

        &-btn {
            font-size: 20px;
            transition: color .4s;
            
            &:hover {
                color: $colorGreen;
            }
        }
    }

    &__header{
        border-bottom: 2px solid $colorGrayLight;
    }

    &__headline {
        margin: 0 0 1em 0;
        font-size: 24px;

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;

            @media screen and (max-width: 1024px) {
                margin-bottom: 26px;
            }

            svg {
                position: relative;
                top: -2px;
            }
        }
    }

    &__ignore-padding-bg {
        position: relative;
        padding: 10px 0;

        &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: -20px;
            z-index: -1;
            height: 100%;
            width: calc(100% + 40px);
            background-color: $colorIce;
        }
    }

    &__btn-wrap {   
        display: flex;
        flex-flow: column;


        @media screen and (min-width: 768px) {
            flex-flow: row;
            justify-content: space-between;
        }
    }

    &__icon {

        &--close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }

    &-hide-on-mobile {
        @media screen and (max-width: 1024px) {
            display: none !important;
        }
    }

    &-hide-on-desktop {
        @media screen and (min-width: 1025px) {
            display: none !important;
        }
    }

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.5);

        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    &-wrapper-confirmation {
        max-width: 760px;
        padding: 40px 60px;

        @media screen and (max-width: 1024px) {
            padding: 40px;
        }

        .btn {
            @media screen and (max-width: 767px) {
                width: 100%;
            }

            + .btn {
                margin-left: 20px;

                @media screen and (max-width: 767px) {
                    margin-top: 24px;
                    margin-left: 0;
                }
            }
        }
    }

    &__details {
        &-wrapper {
            padding: 20px;
            
            @media screen and (max-width: 767px) {
                padding: 20px 0;
            }
        }

        &-footer {
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding-top: 30px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: -30px;
                width: calc(100% + 60px);
                height: 1px;
                background-color: $colorIce;
            }

            @media screen and (max-width: 767px) {
                justify-content: center;
            }
        }
    }

    &__form-wrapper {
        padding: 10px 10px 6px;
    }
    
    &__footer {
        position: relative;
        left: -72px;
        margin-top : 25px;
        padding: 38px 108px;
        width: calc(100% + 72px * 2);

        @media all and (max-width: 900px) {
            left: -20px;
            width: calc(100% + 20px * 2);
            padding: 38px 20px;
        }

        @media all and (min-width: 901px) and (max-width: 1280px) {
            padding: 38px 72px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        @media all and (min-width: 1281px) {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }

    &.popup--with-footer {

        @media all and (max-width: 900px) {
            padding-bottom: 0;
            padding-top: 54px;
        }

        @media all and (min-width: 901px) {
            padding: 72px 72px 0;
        }

        &.show {
            @media all and (min-width: 1250px) {
                overflow: visible;
            }

            .product-count   {

                @media all and (min-width: 901px) {
                    overflow: visible;
                }
            }
        }

        &:not(.show) {
            .splide.is-initialized {
                visibility: hidden!important;
            }
        }

        .product-count   {

            @media all and (min-width: 901px) {
                width: 360px;
            }

            .product-count__value {
                width: 33.3333333333%;
                justify-content: center;
                flex: 1;
                
                @media all and (max-width: 1024px) {
                    width: 50%;
                }

                .product-count__button {

                    @media screen and (min-width: 1025px) {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
            
                        &:first-child { 
                            left: 0; 
                        }
            
                        &:nth-last-child(2),
                        &:last-child { 
                            right: 0;                             
                        }
                    }
                }
            }

            .product-count__total {

                @media all and (max-width: 1024px) {
                    width: 50%;
                }
            }

            .product-count__price-per {
                background-color: #FFF;

                @media all and (min-width: 901px) {
                    overflow: visible;
                }

                @media all and (max-width: 1024px) {
                    width: 1000%;
                }
            }
        }
    }

    &[data-action="advent-prize"] {
        background-color: #4D6A50;
        color: $colorWhite;
        padding: 72px 24px 24px 24px !important;
        
        @media all and (min-width: 768px) {
            padding: 24px !important;
        }
        
        @media all and (min-width: 1200px) {
            padding: 36px !important;
        }

        .popup__close {
            top: 12px;
            right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: rgba($colorWhite, 0.2);
            padding: 0;

            @media all and (min-width: 1200px) {
                top: 24px;
                right: 24px;
            }
        }
    }
}

.popup-summary {
    border-bottom: 2px solid $colorGrayLight;

    &__total {
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
        }
    }

    &__products {
        display: flex;
        flex-direction: column;
        margin: 1em 0;
    }

    .popup-product {
        $block-name: '.popup-product';
        display: flex;
        flex-direction: column;
        color: $colorBlack;
        transition: opacity 0.5s;
        gap: 8px;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
        
        @media all and (min-width: 576px) {
            flex-direction: row;
            align-items: center;
            gap: 16px;
        } 

        &:hover {
            opacity: 0.8;

            #{$block-name}__thumbnail {
                border-color: $colorGreen;
            }   
        }

        &__thumbnail {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 93px;
            height: 93px;
            border-radius: 20px;
            border: 2px solid $colorGray2;
            transition: border-color 0.4s;

            img {
                width: 85%;
                height: 85%;
                object-fit: contain;
            }
        }

        &__details {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &__code {
            font-weight: 800;
            font-size: 14px;
            margin: 0 0 6px 0;
        }

        &__title {
            margin: 0;
            font-size: 16px;
            color: $colorBlue;
        }

        &__price {
            margin: 0;
            font-size: 18px;
        }
    }
}
