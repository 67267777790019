/**
*
* Main footer
*
**/

.l-footer {
    --footerBgColor: #1E2E54;
    --footerBaseFontColor: #fff;
    --footerParaColor: rgba(255, 255, 255, 0.5);
    --footerLinkColor: #ffffff;
    --footerLinkHoverColor: rgba(255, 255, 255, 0.5);
    --footerCardBgColor: #ffffff;
    --footerCardHeadlineColor: #1D1D1D;
    --footerAddressColor: rgba(255, 255, 255, 0.5);

    background-color: var(--footerBgColor);
    color: var(--footerBaseFontColor);

    &--dark {
        --footerBgColor:#1D1D1D;
    }

    &--with-sidebar {
        @media screen and (min-width: 1025px) {
            padding-left: 95px;
            transition: padding-left 0.3s ease;
        }

        &-open {
            @media screen and (min-width: 1025px) {
                padding-left: 300px;
            }
        }
    }

    &__container {
        display: flex;

        @media all and (max-width: 991px) {
            flex-direction: column;
        }
    }

    &__para {
        color: var(--footerParaColor);
    }

    &__link {
        padding: 5px 0;
        display: inline-block;
        color: var(--footerLinkColor);
        text-decoration: underline;
        transition: all 0.3s ease;

        @media all and (max-width: 991px) {
            padding: 15px 0;
        }

        &:hover {
            color: var(--footerLinkHoverColor);
        }
    }

    &__card {
        padding: 30px 40px;
        background-color: var(--footerCardBgColor);
        border-radius: 24px;
        height: 100%;

        @media screen and (max-width: 991px) {
            max-width: 375px;
            margin:0 auto;
        }

        &-headline {
            color: var(--footerCardHeadlineColor);
        }

        &-img-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 991px) {
                justify-content: center;
            }

            picture {
                margin-top: 10px;
                margin-bottom: 10px;
                width:50%;
                max-width: 105px;
                min-width: 60px;

                &:nth-child(odd) {
                    padding-right: 10px;
                }
                &:nth-child(even) {
                    padding-left: 10px;
                }
            }


        }
    }

    &__item {

        padding: 55px 0;
        flex: 0 1 25%;

            @media all and (max-width: 991px) {
                padding: 0;
            }

        &:first-child {
            margin-right: 60px;
            display: flex;
            flex-direction: column;

            @media all and (max-width: 991px) {
                margin: 0;
            }
        }

        &:nth-of-type(2) {

            @media screen and (min-width: 992px) {
                padding-left: 20px;

                h3 {
                    margin-top: 25px;
                }

            }
        }

        &:nth-of-type(3) {

            @media screen and (max-width: 992px) {
                order: 4;
            }
        }

        &:not(:first-child):not(:last-child) {
            margin-right: 40px;

            @media all and (max-width: 991px) {
                margin: 0;
            }
        }

        &:not(:nth-of-type(2)) {
            @media all and (max-width: 991px) {
                padding-top: 40px;
            }
        }



        @media all and (max-width: 991px) {
            margin: 0;
            text-align: center;
        }
    }

    &__address {
        font-style: normal;
        color: var(--footerAddressColor);

        @media all and (max-width: 991px) {
            margin: 40px 0;
        }
    }

    &__bp {
        margin-top: auto;
    }

    &__menu-list {
        list-style-type: none;
    }

    .social-media {
        margin-top: 40px;

        @media all and (max-width: 991px) {
            display: inline;
        }
    }
}
