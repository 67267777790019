
.newsletter-bar {
    padding-top: 25px;
    padding-bottom: 10px;

    @media all and (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 25px;
    }

    &--with-sidebar {
        @media screen and (min-width: 1025px) {
            padding-left: 95px;
            transition: padding-left 0.3s ease;
        }

        &-open {
            @media screen and (min-width: 1025px) {
                padding-left: 300px;
            }
        }
    }
}
