/**
*
* Variables
*
**/

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);

/* Colors */

$colorMain: #1d1d1d;
$colorGreen: #7bb72b;
$colorGreenEmerald: #00b67a;
$colorGreenLight: #73cf11;
$colorGreen2: #6FA427;
$colorBlue: #1e2e54;
$colorIce: #e8e9ed;
$colorAzure: #eff3fc;
$colorRed: #ff5f5f;
$colorOrange: #ecb500;
$colorGray: rgba(255, 255, 255, 0.5);
$colorGray2: #f2f2f2;
$colorGrayLight: #d6d6d6;
$colorWhite: #ffffff;
$colorGrayDark: #8c8c8c;
$colorSilver: #8e96a9;
$colorGradLightGray: transparent linear-gradient(0deg, #E8E9ED00 0%, #F2F2F2 100%) 0% 0% no-repeat padding-box;
$colorLightBlue: #3FCFFA;
$colorRed2: #DD0000;
$colorOrange2: #F99900;
$colorOrangeBright: #F57926;

$colorDark: #101010;
$colorBlack: #000000;

$colorPaktoolDarkGreen: #0e5f17;
$colorPaktoolLightGreen: #4ab350;
$colorPaktoolDarkGrey: #414042;

$colorGray3: #99a1b1;
$colorGray4: #4b5876;
$colorGray5: #4A4A4A;
$colorGray6: #4E4E4E;
$colorGray7: #FAFAFA;
$colorGray8:#bebebe;

$colorDarkBlue: #1a294b;
$colorGrayDark1: #464646;
$colorGrayLight1: #e8eaee;

$contentLinksDefaultColor: $colorBlue;

//Dark theme

$socialMediaColors: (
    "twitter": #4099ff,
    "linkedin": #007bb6,
    "facebook": #3b5998,
    "googleplus": #dd4b39,
    "email": #3bb55e,
    "instagram": #e1306c,
);

$colors: (
    "white": #fff,
    "black": $colorMain,
    "green": $colorGreen,
    "blue": $colorBlue,
    "ice": $colorIce,
    "red": $colorRed,
    "orange": $colorOrange,
    "gray": $colorGray,
    "gray2": $colorGray2,
    "gray-light": $colorGrayLight,
    "azure": $colorAzure,
    "green-emerald": $colorGreenEmerald,
    "gray-2": $colorGray2,
    "gray-dark": $colorGrayDark,
    "paktool-green-dark": $colorPaktoolDarkGreen,
    "paktool-green-light": $colorPaktoolLightGreen,
    "paktool-green-grey": $colorPaktoolDarkGrey,
    "gray-3": $colorGray3,
    "gray-4": $colorGray4,
    "dark": $colorDark,
    "gray-5": $colorGray5,
    "gray-6": $colorGray6,
    "gray-7": $colorGray7,
    "dark-blue": $colorDarkBlue,
    "gray-dark-1": $colorGrayDark1,
    "gray-8": $colorGray8,
    "orange-bright": $colorOrangeBright,
);

$colorsGradient: (
    "grad-light-gray": $colorGradLightGray,
);

$btnColors: (
    "white": #fff,
    "green": $colorGreen,
    "green-2": $colorGreen2,
    "blue": $colorBlue,
    "ice": $colorIce,
    "paktool-green-dark": $colorPaktoolDarkGreen,
    "paktool-green-light": $colorPaktoolLightGreen,
    "paktool-green-grey": $colorPaktoolDarkGrey,
    "gray-light-1": $colorGrayLight1,
);

$bgColorsWhiteFont: (
    "dark": $colorDark,
    "black": $colorMain,
    "green": $colorGreen,
    "blue": $colorBlue,
    "red": $colorRed,
    "paktool-green-dark": $colorPaktoolDarkGreen,
    "paktool-green-light": $colorPaktoolLightGreen,
    "paktool-green-grey": $colorPaktoolDarkGrey,
    "gray-5": $colorGray5,
    "gray-6": $colorGray6,
    "gray-7": $colorGray7,
    "dark-blue": $colorDarkBlue,
    "gray-dark-1": $colorGrayDark1,
    "orange-bright": $colorOrangeBright,
);

$circleColors: (
    "green": $colorGreen,
    "blue": $colorBlue,
    "orange": $colorOrange2,
    "red": $colorRed2,
    "light-blue": $colorLightBlue,
);

$boxShadow: 0 10px 20px rgba(#000000, 0.15);
$boxShadowSmall: 0 5px 10px rgba(#000000, 0.15);

