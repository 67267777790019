.campaign {

    &__card {
        margin-top: 20px;
        width: 100%;
        min-height: 260px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        border-radius: 20px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.15);
        cursor: pointer;

        &:hover {

            div {
                background-color: #5EA600;
            }

            .campaign__card--back {
                background-color: #fafafa;
            }
            
        }

        &.flipped {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }

        div {
            padding: 24px;
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-radius: 20px;
            background-color: #7bb72b;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            -o-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -o-box-align: center;
            align-items: center;
            color: #47525d;
            transition: background-color 0.5s;
        }

        .campaign__card--back {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
            width: 100%;
            background-color: $colorWhite;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        &--image {
            width: auto;
            max-height: 70%;
            margin-bottom: 20px;
        }
    }
}


